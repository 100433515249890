<template>
  <div class="main">
      <img src="@/assets/img/assess/assess (1).jpg" alt="">
      <img src="@/assets/img/assess/assess (2).jpg" alt="">
      <img src="@/assets/img/assess/assess (3).jpg" alt="">
      <img src="@/assets/img/assess/assess (4).jpg" alt="">
      <img src="@/assets/img/assess/assess (5).jpg" alt="">
      <img src="@/assets/img/assess/assess (6).jpg" alt="">
      <img src="@/assets/img/assess/assess (7).jpg" alt="">
      <img src="@/assets/img/assess/assess (8).jpg" alt="">
      <img src="@/assets/img/assess/assess (9).jpg" alt="">
      <img src="@/assets/img/assess/assess (10).jpg" alt="">
      <el-button type="primary" style="margin-left:800px;margin-bottom:50px;display:inline-block" >下载</el-button>
      <el-button type="primary" style="margin-bottom:50px;display:inline-block">打印</el-button>
  </div>
</template>

<script>
export default {
  name: '',
};
</script>

<style scoped lang='less'>
.main{
    background: white;
}
img {
    width: 700px;
    display: block;
    margin: 0 auto;
}

</style>
